/* Auth.css */

.authContainer {
    max-width: 700px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 10px;
    
  }
  
  .form-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  h1 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .social-icons {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;

  }
  
  .social-icons a {
    margin-right: 20px;
    color: #333;
    display: inline-block; 
  }
  
  span {
    color: #333;
    margin-bottom: 10px;
    display: block;
  }
  
  .name-fields {
    display: flex;
    gap: 10px;
  }
  input[type="text"] {
    width: 100%; 
  }
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"]
   {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  a {
    color: #333;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
  }
  
  .signin-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .signin-button:hover {
    background-color: #0056b3;
  }

  .login-link {
    margin-top: 20px;
    text-align: center;
  }
  
  .login-link a {
    color: #007bff;
    text-decoration: none;
  }
  
  .password-field {
    position: relative;
  }
  
  .password-field input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .password-field i {
    position: absolute;
    top: 40%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
  }
  
  /* Adjust icon size as needed */
  .password-field i.bi-eye {
    font-size: 1.5rem;
  }
  
  .password-field i.bi-eye-slash {
    font-size: 1.5rem;
  }

  

/* Style enhancements for the phone number input */
.PhoneInput {
  width: 100%;
  padding-left: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.PhoneInput input {
  flex-grow: 1;
  border: none;
  outline: none;
}

.PhoneInputCountrySelect {
  margin-right: 10px;
  cursor: pointer;
  background-color: #f3f3f3;
  border: none;
  padding: 8px;
  border-radius: 5px;
  display: inline-block;
}

input.error {
  border: 2px solid red;
}

input::placeholder {
  color: #999;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: -8px;
  margin-bottom: 10
}

@media (max-width: 768px) {
  .authContainer {
      padding: 20px;
  }

  .form-container {
      padding: 15px;
  }

  .signin-button {
      width: 100%;
  }
}

@media (max-width: 768px) {
    .name-fields {
      flex-direction: column;
    }
  }