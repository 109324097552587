@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  line-height: 1.500 !important;
  font-size: 1.13em;
  color: #313131;
  text-align: justify;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1200px) {
  body {
    font-size: 1.25em; /* Increase font-size for extra-large screens */
  }
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */