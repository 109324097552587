.pnf {
    display: flex;
    min-height: 65vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .pnf-title {
    font-size: 100px;
    font-weight: 700;
  }
  
  .pnf-heading {
    font-weight: normal;
  }
  
  .pnf-button {
    color: black;
    border: 1px solid black;
    text-decoration: none;
    padding: 10px;
    margin-top: 10px;
  }
  .pnf-button:hover {
    background-color: #0d6efd;
    color: white;
  }