.anzsco-heading {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.anzsco-heading-link {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.group-details-box {
    border: 1px solid black; /* Border for the group details */
    border-radius: 5px;
    padding: 15px;
    background-color: #f6f8fd;
   
}

/* Ensure space between strong elements and text */
.group-details-box p {
    font-size: 16px;
    margin: 12px 0;
    line-height: 1.8; /* Increase line height for more space between lines */
    width: 100%;
   
}

.group-details-box strong {
    font-weight: bold !important;
    color: #333 !important;
    margin-right: 10px !important; /* Add space between strong text and the following word */
}

/* Media queries for responsiveness */

/* For tablets and larger phones (portrait mode) */
@media (max-width: 768px) {

    .anzsco-heading {
      font-size: 22px;
      margin-bottom: 15px;
    }

    .group-details-box {
      padding: 12px; /* Slightly larger padding for tablets */
    }

    .group-details-box p {
      font-size: 14px; /* Adjust font size for tablets */
      margin: 10px 0; /* Adjust paragraph margin for tablets */
      line-height: 1.7; /* Adjust line height for tablets */
    }

    .group-details-box strong {
      font-weight: bold !important;
      color: #333 !important;
      margin-right: 8px !important; /* Adjust space between strong text and the following word */
    }
}

/* For small phones (portrait mode) */
@media (max-width: 480px) {

    .anzsco-heading {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .group-details-box {
      padding: 10px; /* Smaller padding for mobile devices */
      text-align: left; /* Left-align the text for better readability on mobile */
    }

    .group-details-box p {
      font-size: 14px; /* Smaller font size for mobile */
      margin: 8px 0; /* Adjust paragraph margin for mobile */
      line-height: 1.8; /* Adequate line-height for readability */
      display: flex; /* Use flexbox for better alignment */
      justify-content: space-between;
    }

    .group-details-box strong {
      font-weight: bold !important;
      color: #333 !important;
      margin-right: 10px !important; /* Add space between strong text and the following word */
    }
}


.selected-result-box-unit {
    width: 100% !important;
    height: auto; /* Adjust height to fit the content */
    padding: 10px 20px;
    margin: 20px auto; /* Center the result box horizontally */
    display: flex; /* Use flexbox for vertical and horizontal centering */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    border: 2px solid #e9ecf6; /* Light border similar to the image */
    border-radius: 5px; /* Rounded corners */
    background-color: white; /* Light background */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    text-align: center; /* Ensure text is centered */
}

.selected-result-text-unit {
    font-size: 22px; /* Slightly larger font for the text */
    font-weight: bold;
    color: #333; /* Darker text color */
    margin: 0; /* Remove margin for clean look */
    line-height: 1.5; /* Adjust line height for better spacing between lines */
    display: inline; /* Ensure inline display for proper spacing */
}

.description-section {
    margin-top: 20px;
    
}



.description-section strong {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    display: block; /* Ensures that the label appears above the box */
}



.description-box {
    background-color: #f1f1f1; /* Light gray background to make the description stand out */
    border-radius: 5px; /* Slightly rounded corners for a modern look */
    padding: 20px; /* Add padding inside the description box for readability */
    font-size: 16px; /* Description text size */
    line-height: 1.5; /* Better line spacing */
    color: #333; /* Darker text for contrast */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}

.description-section .description-box p {
    margin: 0; /* Ensure paragraphs inside the description have no extra margin */
}

@media (max-width: 768px) {
    /* Make sure the description box is responsive on smaller screens */
    .description-box {
        padding: 15px;
        font-size: 14px;
    }
}

/* Skill Level Section */

.skill-level-description {
    background-color: #001f80; /* Slightly lighter blue for the content box */
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px; /* Space between skill boxes */
    color: white;
}


.skill-level-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px; /* Add more space between items */
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
    color: white;
}

.skill-level-text {
    font-size: 16px;
    line-height: 1.6;
    color: white;
}


/* occupations-section */
.occupations-section {
    padding: 15px;
    background-color: #ffffff; /* White background */
    border: 1px solid #000000; /* Black border */
    border-radius: 8px; /* Keep the rounded corners */
    margin-bottom: 20px;
  }
  
  
  .occupations-header {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .occupations-list {
    padding-left: 30px;
    list-style-type: none;
  }
  
  .occupation-item {
    font-size: 16px;
    padding: 5px 0;
    color: black;
    display: flex; /* Use flexbox to align code and title */
    align-items: baseline; /* Ensures the title is vertically aligned with the code */
  }
  
  .occupation-code {
    text-decoration: none;
    color: #cb0731; /* Orange color for the occupation code */
    font-weight: bold;
    margin-right: 10px; /* Space between the code and title */
    min-width: 80px; /* Ensure the code has a minimum width for alignment */
    display: inline-block;
  }
  
  .occupation-title {
    display: inline-block;
    color: #555;
    word-wrap: break-word; /* Ensures the title wraps if it's too long */
  }
  
  .occupation-code:hover {
    color:#cb0731; /* Darker orange on hover */
  }
  
  .occupation-code::before {
    content: "\2022"; /* Adds a bullet point */
    color: #cb0731; /* Matches the occupation code color */
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  
  
/* Tasks Section */
/* Tasks Section Styling */

  
  .tasks-header {
    display: flex;
    align-items: center;
    background-color: #cb0731; /* Orange background */
    color: white;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer; /* Make it clickable */
  }
  
  .tasks-header strong {
    margin-left: 10px;
    font-size: 20px; /* Increased font size for header */
  }
  
  .icon {
    font-size: 18px;
    color: white; /* Set icon color to white */
    display: inline-block;
  }
  
  .tasks-list {
    padding: 15px 30px; /* Adds padding to the left and right sides */
    margin-top: 10px;
    font-size: 18px; /* Increased font size for better readability */
    background-color: #fff;
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 0 0 8px 8px;
    list-style-type: decimal; /* Numbers for the list */
  }
  
  
  .task-item {
    padding: 8px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .task-item:last-child {
    border-bottom: none;
  }
  
  /* Icon for Expand/Collapse */
  .icon {
    font-size: 20px;
    margin-top: 10px;
  }
  
  
  