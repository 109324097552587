.toolbar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}



.nav-links .MuiButton-root {
  margin: 0 10px;
}

.MuiPopover-paper {
  background-color: white; /* Ensures dropdowns have a white background */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  border-radius: 4px; /* Rounds the corners of the dropdown */
}

.visas-menu {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  
}

.search-bar {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: white;
  width: 90%;
}

.search-bar-text {
  width: 50%;
  padding: 10px;
}

.nav-button:hover {
  background-color: white;
  /* color: #001f80 !important; */
  font-weight: bold;
  text-decoration: none;
}

/* Default style for all screen widths at least 1200px wide */
@media (min-width: 1000px) {
  .nav-button {
    padding-right: 15px;

  }
}

/* Large devices (desktops, 992px to 1199px) */
@media (max-width: 1250px) {
  .nav-button {
    padding-right: 20px; /* Slightly less padding for smaller desktops */
  }
}

/* Medium devices (tablets, 768px to 991px) */
@media (min-width: 1350px) {
  .nav-button {
    padding-right: 40px;
    
  }
}
@media (max-width: 1090px) {
  .nav-button {
    padding-right: 50px;
   
   
  }
}
/* Small devices (landscape phones, 576px to 767px) */
@media (max-width: 767px) {
  .nav-button {
    padding-right: 20px; /* Further reduced padding for large phones */
  }
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575px) {
  .nav-button {
    padding-right: 20px; /* Minimal padding for small phones */
  }
}

.menu-item-li {
  list-style: none;
  
  padding-left: 0;
}

.menu-item-li-a {
  color: black;
  text-decoration: none;

}

.menu-item-li-a:hover {
  background-color: white;
  /* color: #001f80; */
  text-decoration: none;
}

.menu-item:hover {
  background-color: white !important;
  color: black;
  text-decoration: none;
  font-weight: bold;
}



.visa-type {
  color: black !important;
}





.nav-links .nav-button:hover {
  /* color: #001f80; */
  font-weight: bold;
}

.dropdownmenu {
  position: relative;
}

.dropdownmenu::after {
  content: "";
  position: absolute;
  top: 100%; /* Start at the bottom of the dropdownmenu */
  width: 100%;
  height: 30px; /* Extend 20px down */
  visibility: hidden; /* Invisible by default */
}

.dropdownmenu:hover::after,
.dropdownmenu:hover #submenu-about,
.dropdownmenu:hover #submenu-student,
.dropdownmenu:hover #submenu-profile,
.dropdownmenu:hover #submenu-visas,
#submenu-visas:hover {
  visibility: visible; /* Make visible on hover */
}

#submenu-about {
  list-style: none;
  background-color: white;
  font-size: 16px;
  position: absolute;
  top: 230%; /* Adjusted to be exactly under the link */
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.05s ease;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 180px; /* Ensure it fits the menu items */
}

#submenu-profile {
  list-style: none;
  background-color: white;
  position: absolute;
  top: 230%; /* Adjusted to be exactly under the link */
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.05s ease;
  padding: 8px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 160px; /* Ensure it fits the menu items */
}
#submenu-visas {
  list-style: none;
  background-color: white;
  position: absolute;
  top: 230%; /* Adjusted to be exactly under the link */
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.05s ease;
  padding: 0px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 360px; /* Ensure it fits the menu items */
}

#submenu-student {
  list-style: none;
  background-color: white;
  position: absolute;
  top: 230%; /* Adjusted to be exactly under the link */
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.05s ease;
  padding: 0px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 200px; /* Ensure it fits the menu items */
}


.dropdownmenu:hover #submenu-about,
.dropdownmenu:hover #submenu-student,
.dropdownmenu:hover #submenu-profile,
.dropdownmenu:hover #submenu-visas {
  visibility: visible;
  opacity: 1;
}

#submenu-about li, #submenu-student li, #submenu-profile li, #submenu-visas li {
  width: 100%;
  float: none;
}

#submenu-about a, #submenu-student a {
  color: black;
  padding: 0px 10px;
  text-align: left;
  text-decoration: none;
}
#submenu-profile a {
  color: black;
  padding: 8px 10px;
  text-align: left;
  text-decoration: none;
}
#submenu-visas a {
  color: black;
  padding: 0px 10px;
  text-align: left;
  text-decoration: none;
}

#submenu-about a:hover, #submenu-student a:hover, #submenu-profile a:hover, #submenu-visas a:hover {
  background-color: white;
  color: #001f80;
}

.visas-menu .column {
  flex: 1;
  /* min-width: 450px; /* Ensure columns don't get too narrow */
  /* max-width: 450px;  */

}




.nav-links .MuiButton-root {
  margin: 0 10px;
}

.MuiPopover-paper {
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.visas-menu {
  display: none;
  position: absolute;
  top: 150%;
  left: 0;
  background-color: white;
  padding: 10px 0;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdownmenu:hover .visas-menu {
  display: block;
}

.menu-item-li {
  list-style: none;
  font-size: 14px;
  padding-left: 0;
}

.menu-item-li-a {
  color: black;
  text-decoration: none;
  display: block;
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
}

.menu-item-li-a:hover {
  background-color: #f5f5f5;
  /* color: #001f80; */
}

.submenu {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: white;
  padding: 10px 20px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  white-space: nowrap;
  width: 350px;

}

.menu-item-li-a:hover .submenu {
  display: block;
  text-decoration: none;
  list-style: none;
}

.submenu-item-li-a {
  color: black;
  text-decoration: none;
  display: block;
  padding: 10px 20px;
}

.submenu-item-li-a:hover {
  background-color: #f5f5f5;
  color: #001f80;
}

 

.visa-type {
  color: black !important;
}

.nav-links {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 10px ;
  margin: 0;
  justify-content: space-between;
}

 .nav-button {
 
  transition: all 0.05s ease;
}

.nav-links .nav-button:hover {
  color: #001f80;
  font-weight: bold;
}

.dropdownmenu {
  position: relative;
}



/*  */



.dropdown-container {
  position: relative;
}

.dropdown-label {
  cursor: pointer;
  display: inline-block;
  margin-left: 20px;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 80%;
  left: 30px;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 10; /* Ensure dropdown appears above other content */
  min-width: 150px;
  padding: 8px 0;
}

.dropdown-content.open {
  display: block;
}

.dropdown-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-content li {
  padding: 8px 16px;
}

.dropdown-content a {
  display: block;
  color: black;
  text-decoration: none;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}
.submenu1 {
  display: none;
  padding-left: 20px;
  background-color: #f9f9f9;
  border-left: 2px solid #e0e0e0;
  margin-left: 10px;

}

.submenu1.open {
  display: block;
  
  background-color: white;
  
}