/* #001f80 blue
#cb0731 red */

.subclass-189-page-font{
    font-size: 0.94em !important;
}

.image-container {
    overflow: hidden; /* Ensures the image stays within the container during the zoom effect */
}

.subclass-189-image {
    height: 100%;
    width: 90%;
    transition: transform 0.3s ease-in-out; /* Adds smooth transition for the zoom effect */
}

.subclass-189-image:hover {
    transform: scale(0.9); /* Scales down the image to 90% of its original size on hover */
}
.subclass-189-h1{
    color: #001f80 ;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
}
.subclass-189-h1-span{
    color:#cb0731 ;
}
/* .subclass-189-p{
    font-family: "Montserrat", sans-serif;
    font-size: 0.94em !important;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
    text-align: justify;
} */

.subclass-189-a{
    color: #001f80;
    text-decoration: underline;
}
.subclass-189-li {
        
        margin-bottom: 8px;
    }

/* .................process time............ */
.subclass-189-pt-col{
    width: 90%;
}
.subclass-189-pt-h1{
    font-weight: 600;
    font-size: calc(1.01rem + 0.6vw);
}

/* ...........visa qualify row............... */
.subclass-189-vq-row{
    display: flex;
    height: 50%;
    width: 100%;
    background-color: #001f80;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.subclass-189-vq-button {
    background-color: #001f80; /* Blue background color */
    color: white; /* White text color */
    border: 2px solid white; /* Blue border color */
    padding: 10px 20px; /* Padding for the button */
    font-size: 16px; /* Font size for the button text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
}

.subclass-189-vq-button:hover {
    background-color: white; /* White background color on hover */
    color: black; /* Black text color on hover */
    border: 2px solid black; /* Black border color on hover */
}
.subclass-189-vq-btn-margin {
    margin-right: 15px; /* Adjust the margin as needed */
}

/* .......... related visas card ............... */
.subclass-189-rv-card-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19); /* Shadow effect */
    padding: 15px; /* Add some padding inside the border */
    background-color: #ffffff; /* Background color to make the shadow visible */
    border-radius: 5px; /* Optional: Add rounded corners */
  }
.subclass-189-rv-card-head{
    background-color: #001f80;
    color: white;
    height: 50px;
    
}
.subclass-189-rv-card-h{
    padding: 10px;
    font-weight: 600;
    font-size: calc(1.03rem + 0.01vw);
}
.subclass-189-rv-card-p{
    color: #001f80;
    font-size: 1em !important;
    margin-bottom: 10px;
    margin-top: 10px;

}
.subclass-189-li-a {
    color:#001f80 ; /* Inherit color from parent element */
    text-decoration: none; /* Remove underline */
  }
  
  .subclass-189-li-a:hover {
    text-decoration: underline; /* Add underline on hover */
  }

  /* faq section */
  
  .testimonial-background-accordion{
    background: linear-gradient(to left, white,#8490b5);
   
  }

  .visa_question-acc {
    background: linear-gradient(to right, white, #8490b5) !important;
    padding: 100px 0 !important;
    height: 800px !important; /* Default for desktop */
  }
  
  @media (max-width: 1199.98px) {
    .visa_question-acc {
      padding: 50px 0 !important;
      height: 700px !important; /* Adjust height as needed for tablet view */
    }
  }
  
  @media (max-width: 767.98px) {
    .visa_question-acc {
      padding: 30px 0 !important;
      height: auto !important; /* Adjust height as needed for mobile view */
    }
  }

  /* home.css */
  .home-h1 {
    color: #001f80;
    font-weight: bold;
    font-size: 2rem; /* Base size for large screens */
  }
 
  .span-text {
    color: #cb0731;
    padding-left: 5px;
  }
  
  @media (max-width: 768px) {
    .home-h1 {
      font-size: 1.5rem; /* Smaller size for smaller screens */
    }
  }

  .btn-enquire, .btn-appointment {
    background-color: transparent;
    transition: background-color 0.3s, color 0.3s;
    width: auto; /* Auto width for large screens, override below for small screens */
  }
  
  .btn-enquire {
    color: #001f80;
    border: 2px solid #001f80;
  }
  
  .btn-enquire:hover, .btn-enquire:focus {
    background-color: #001f80;
    color: white;
  }
  
  .btn-appointment {
    color: #cb0731;
    border: 2px solid #cb0731;
  }
  
  .btn-appointment:hover, .btn-appointment:focus {
    background-color: #cb0731;
    color: white;
  }
  
  

  @media (max-width: 768px) {
    .btn {
      display: block; /* Each button on its own line */
      width: 100%; /* Full width buttons */
      margin-top: 10px;
    }
  }

  
  .bannerImage, .aboutUs {
    width: 100%; /* Responsive width */
    height: auto; /* Maintain aspect ratio */
  }

  .aboutUs2{
    width: 90%;
    height:70%;
    
    margin-top: 50px !important;
  }
  
  @media (max-width: 768px) {
    .bannerImage {
      height: auto; /* Adjust height dynamically based on width */
    }
    .aboutUs {
      height: auto; /* Ensure the image height adjusts to maintain aspect ratio */
    }
  }

  .about-us-h4 {
    display: inline; 
    
  }
  
  @media (max-width: 768px) {
    .about-us-h4 {
      white-space: normal; /* Allow wrapping on small screens */
    }
  }

  .choose-us-title-view h2 {
    text-align: center;
    color:  #001f80;
    font-size: 2rem;
    margin-bottom: 1rem; 
  }
  
  .choose-us-text {
    
    text-align: justify;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1.5rem; /* Adds bottom margin for spacing */
  }
 
 




.col-lg-4 h4, .col-md-6 h4, .col-sm-12 h4 {
  font-size: 1.5rem;
  color: #003366;
}

/* .col-lg-4 p, .col-md-6 p, .col-sm-12 p {
  font-size: 1rem;
 
} */
 
.about-us-h4 {
  font-size: 1.5rem;
  color: #003366;
}

.mt-3 {
  margin-top: 1rem;
}

/* Custom Tabs Styles */
.custom-tabs {
  display: flex;
  justify-content: space-around; /* Distribute space evenly */
}

.custom-tabs .nav-link {
  text-align: center;
  color: #666;
  font-size: 1rem;
  padding: 10px;
  border: none;
  flex: 1; /* Allow flex items to grow equally */
}

.custom-tabs .nav-link i {
  color: #cb0731;
}

.custom-tabs .nav-link.active {
  color: #003366;
  font-weight: bold;
  border-bottom: 3px solid  #003366;
}

/* .custom-tab-content .custom-tab-pane {
  padding: 20px;
} */

.custom-tab-content .custom-tab-pane h4 {
  font-size: 1.5rem;
  color: #003366;
}

/* .custom-tab-content .custom-tab-pane p {
  font-size: 1rem;
  color: #666;
} */
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-container2 {
  display: flex;
  justify-content: center;
  
}

.tab1-image {
  height: 300px;
  width: 500px;
}

.tab1-image {
  transition: transform 0.3s ease; 
}

.tab1-image:hover {
  transform: scale(1.1); 
}
  

  @media (max-width: 768px) {
    .choose-us-title-view h2 {
      font-size: 1.5rem; /* Smaller font size for tablets and mobile phones */
    }
  
    .choose-us-text {
      font-size: 0.9rem; /* Smaller font size for better readability on smaller screens */
    }
  }
  
  @media (max-width: 576px) {
    .choose-us-title-view h2 {
      font-size: 1.25rem; /* Even smaller font size for very small devices */
    }
  }
  
  
  .f-card-main-section{
    margin-top: 70px;
  }
  .f-card-container {
    position: relative;
    perspective: 1000px;
    height: 300px;
    flex: 0 0 31%; /* Adjust this value to get close to the desired width */
    max-width: 31%; /* Ensure the card doesn't grow beyond this width */
  }
  @media (max-width: 992px) { /* Medium screens (992px and below) */
    .f-card-container {
      flex: 0 0 48%; /* Set width to 48% to give some space between cards */
      max-width: 50%; /* Ensure the card doesn't grow beyond this width */
      margin-bottom: 2%; /* Add some space between rows */
    }
  }
  
  @media (max-width: 768px) { /* Small screens (768px and below) */
    .f-card-container {
      flex: 0 0 100%; /* Set width to 100% for small screens */
      max-width: 100%; /* Ensure the card doesn't grow beyond this width */
      margin-bottom: 10px; /* Add some space between rows */
    }
  }

  .f-card-container:hover .card-front {
    transform: rotateY(180deg);
  }
  
  .f-card-container:hover .card-back {
    transform: rotateY(0deg);
  }
  
  .card-back {
    transform: rotateY(180deg);
  }
  
  .card-f {
    position: absolute;
    height: 100%;
    width: 100%;
    transition: all 3s;
    backface-visibility: hidden;
    border-radius: 20px;
    border: 1px solid #ccc;
  }
  
  .card-back .f-card-body {
    position: relative;
    display: flex;
    top: 40%;
    align-items: center;
    justify-content: center;
  }
  
  .f-card-body a {
    display: block;
    text-align: center;
    padding: 10px 20px;
    background-color: #cb0731;
    color: white;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .card1 {
    background-color: #cb0731;
    color: white;
  }
  
  .card2 {
    background-color: white;
    color: black;
  }
  
  .card3 {
    background-color: #001f80;
    color: white;
  }
  
  /* //visa services card  */
  /* services represent with s */
  
  .s-card-heading{
    text-align: center;
  }
  
   /* {our testimonial  section} */
  .testimonial-background{
    background: linear-gradient(to right, white,#8490b5);
  
   
  }
  .testimonial-heading{
    text-align: center;
    
  }
  
  .testimonial-background {
    background-color: #f9f9f9;
    padding: 100px 0;
    height: 400px;
  }
  
  .testimonial-heading {
    margin-bottom: 20px;
  }
  
  .testimonial-box {
    background: linear-gradient(to bottom, white,#8490b5);
    border-radius: 10px;
    padding: 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial-content {
    text-align: center;
  }
  
  .testimonial-message {
    
    color: #333;
    margin-bottom: 10px;
  }
  
  .testimonial-name {
    font-size: 0.9rem;
    color:#001f80;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .testimonial-dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .testimonial-dots .dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .row {
    --bs-gutter-x: rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y)* -1);
    margin-right: calc(var(--bs-gutter-x)* -.5);
    margin-left: calc(var(--bs-gutter-x)* -.5);
}

  .testimonial-dots .dot.active {
    background-color: #333;
  }

  @media (max-width: 575.98px) {
    .testimonial-background {
      padding: 40px 0;
      height: 700px; /* Adjust height as needed for mobile view */
    }
  }

  @media (min-width: 576px) and (max-width: 1199.98px) {
    .testimonial-background {
      padding:100px 0;
      height: 500px; /* Adjust height as needed for tablet view */
    }
  }

  .education-img{
    /* height: "70%", width: "80%", margin: "10px" */
    height: 70%;
    width: 80%;
    margin: 10px;
  }
  .migration-img{
    height: 80%;
  }

  
  /* media section */

  
  .media-image {
    height: 30%;
    width: 80%;
  }
  
  .icon-scroll {
    overflow-x: auto; /* Allows horizontal scrolling */
    white-space: nowrap; /* Keeps all icons in a single line */
    width: 100%; /* Adjusts the width to fill the container */
  }
  
  .scrolling-wrapper {
    display: flex; /* Aligns children (icons + text) in a row */
    animation: scroll 5s linear infinite;
    align-items: center; /* Centers items vertically */
    border: none; /* Make sure no border is applied */
    text-decoration: none;
  }
  
  .social-link-media,
  .social-link-media:hover,
  .social-link-media:visited,
  .social-link-media:focus {
    display: flex;
    align-items: center;
    margin-right: 40px;
    font-weight: bold;
    text-decoration: none; /* Removes the underline */
    border: none !important; /* Remove any borders */
    outline: none !important; /* Remove focus outlines */
    box-shadow: none !important; /* Remove box shadows if any */
    color: inherit; /* Keeps the font color consistent */
  }
  
  .social-link-media:hover {
    color:#001f80; /* Changes text and icon color on hover */
    cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
  }
  .social-link-media-y:hover {
    color:red; /* Changes text and icon color on hover */
    cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
  }
  .social-link-media-insta:hover {
    color:rgb(199, 48, 83); /* Changes text and icon color on hover */
    cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
  }
  .social-link-media {
    text-align: center;
    margin-right: 30px; /* Adjust margin as needed */
  }
  
  .social-link-media span {
    margin-top: 10px; /* Space between icon and text */
  }
  
  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  @media (max-width: 576px) {
    .media-main-div {
      margin-top: 300px;
    }
  }
 
  .span-fee-text {
    color: white;
  }
  
  .btn {
    border-radius: 50px; /* Rounded buttons */
  }
  
  .btn-enquire-appointment {
    color: white;
    background-color: #cb0731;
    width: 100%;
  }
  
  .btn-enquire-appointment:hover,
  .btn-enquire-appointment:focus {
    background-color: white;
    color: #cb0731;
  }
  
  /* For 2nd button */
  .btn-enquire-card2-appointment {
    color: white;
    background-color: #001f80;
    width: 100%;
  }
  
  .btn-enquire-card2-appointment:hover,
  .btn-enquire-card2-appointment:focus {
    background-color: #cb0731;
    color: white;
  }
  
  /* For 3rd button */
  .btn-enquire-card3-appointment {
    color: #001f80;
    background-color: white;
    width: 100%;
  }
  
  .btn-enquire-card3-appointment:hover,
  .btn-enquire-card3-appointment:focus {
    background-color: #001f80;
    color: white;
  }
  
  .appointment-card1-body {
    color: white;
    background-color: #001f80;
  }
  
  .appointment-card3-body {
    color: white;
    background-color: #cb0731;
  }
  
  .appointment-cards-hight {
    height: 100%;
    border-radius: 15px; /* Rounded card corners */
  }
  
  .services {
    list-style-type: none;
    padding: 0;
  }
  
  .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px; /* Ensure buttons are at the bottom of the card */
  }
  
  .card {
    display: flex;
    flex-direction: column;
  }
  






  
  
  