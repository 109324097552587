.social-media-icons-aboutus {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 40px;
  }
  .social-media-icons-aboutus a {
    font-size: 30px;
    color: inherit; /* Use default color for all icons */
    transition: color 0.3s;
  }
  .youtube-icon-aboutus {
    color: #FF0000 !important;
  }
  .facebook-icon-aboutus {
    color: #4267B2 !important;
  }
  .twitter-icon-aboutus {
    color: #1DA1F2 !important;
  }
  .instagram-icon-aboutus {
    color: #C13584 !important;
  }
  .linkedin-icon-aboutus {
    color: #0077B5 !important;
  }
  .social-media-icons-aboutus a:hover {
    opacity: 0.7;
  }