.sidebar{
    /* background-color: lightcoral; */
    flex: 1;
    border-right: 0.5px solid gray;
    min-height: 100vh;
    background-color: white;
    .top{
        
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        
        
    }

    hr{
        height: 0;
        border: 0.5px solid gray;
    }

    .center{
        padding-left: 10px;
        
        
        ul{
            list-style: none;
            padding: 0;
            margin: 0;

            p{
                margin: 0 !important;
                padding: 0 !important;
                
            }
            .title{
                font-size: 20px;
                font-weight: bold;
                color: #555;
            
            }
            li{
                display: flex;
                padding: 2px;
                cursor: pointer;

                &:hover{
                    background-color: #ece8ff;
                    
                }

                .icon{
                    font-size: 25px;
                    color: rgb(21, 21, 120);
                }   

                span{
                    font-size: 20px;
                    font-weight: 400;
                    color: #777;
                }

            }
           
        }
        
    }
}