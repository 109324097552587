/* Custom card background color */
.bg-custom {
    background-color: #F6F8FD !important;
}

/* Image Styling */
.education-img {
    max-width: 60% !important;
    height: auto !important;
    margin-left: 150px !important;
}

@media (max-width: 768px) {
    .education-img {
        display: none !important; /* Hide the image on small screens */
    }
    
    /* Ensure card margin and padding are zero on small screens */
    .card {
        margin: 0 !important;
    }

    .card-body {
        padding: 0 !important;
        margin: 0 !important;
    }

    .btn-enquire {
        width: 100% !important;
        margin-bottom: 10px !important;
    }
}

/* Toggle button styling */
.toggle-container {
    display: inline-flex !important;
    align-items: center !important;
    cursor: pointer !important;
    margin-bottom: 15px !important;
}

.toggle-switch {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 50px !important;
    height: 25px !important;
    background-color: #cb0731 !important;
    color: white !important;
    border-radius: 25px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    margin-right: 10px !important;
    transition: background-color 0.3s ease !important;
}

.toggle-switch.on {
    background-color: #cb0731 !important;
}

.toggle-switch.off {
    background-color: #bbb !important;
}

.toggle-label {
    font-size: 16px !important;
    color: #333 !important;
    margin-top: 10px !important;
}

/* Search Bar Styling */
.search-bar input {
    width: 100% !important;
    height: 50px !important;
    padding: 15px !important;
    font-size: 18px !important;
    margin-top: 10px !important;
    color: #000 !important;
}

.search-bar .search-btn {
    padding: 10px 10px !important;
    margin-top: 10px !important;
    font-size: 16px !important;
    background-color: #cb0731 !important;
    color: white !important;
    border: none !important;
    height: 50px !important;
    border-radius: 0% !important;
}

/* Suggestions Styling */
.suggestions {
    font-size: 14px !important;
    color: #777 !important;
    margin-top: 10px !important;
}

.highlighted {
    color: #cb0731 !important;
    font-weight: bold !important;
}

/* Icon button section styles */
.icon-button-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-item {
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 10px;
}

.icon-style {
    font-size: 28px;
}

/* Specific colors for each icon */
.icon-circle-1 {
    background-color: #e6e7ee;
}

.icon-style-1 {
    color: #1c1b4d;
}

.icon-circle-2 {
    background-color: #ffece5;
}

.icon-style-2 {
    color: #ff6f47;
}

.icon-circle-3 {
    background-color: #efe8ff;
}

.icon-style-3 {
    color: #a980ff;
}

.icon-circle-4 {
    background-color: #e7f8f3;
}

.icon-style-4 {
    color: #45cba6;
}

.icon-circle-5 {
    background-color: #e6e7ee;
}

.icon-style-5 {
    color: #1c1b4d;
}

.icon-item p {
    font-size: 14px !important;
}

/* Hover effect */
.icon-item:hover {
    cursor: pointer;
    transform: translateY(-5px);
    transition: transform 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .icon-button-section {
        flex-direction: column;
    }

    .icon-item {
        margin-bottom: 20px;
    }

    .search-btn {
        width: 100%;
        margin-top: 10px;
    }
}

.suggestions-dropdown {
    max-height: 250px; /* Set the max height for the dropdown */
    overflow-y: auto;  /* Enable vertical scroll if content exceeds height */
    position: absolute;
    z-index: 1000; /* Ensure the dropdown appears above other content */
    width: 100%;  /* Match width to the input field */
    background-color: white;
    border: 1px solid #ddd; /* Optional: Add a border to the dropdown */
}

/* Style for active list item */
.suggestions-dropdown .list-group-item.active {
    background-color: #001f80; /* Change background color when hovered */
    color: white;
}

/* Style for list group items */
.list-group-item {
    font-size: 14px; /* Smaller font size for dropdown items */
    padding: 10px; /* Add some padding to the items */
    cursor: pointer;
}

/* Custom scrollbar styling */
.suggestions-dropdown::-webkit-scrollbar {
    width: 8px;
}

.suggestions-dropdown::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

/* Media queries for responsiveness */

/* For tablets and larger phones (portrait mode) */
@media (max-width: 768px) {
    .suggestions-dropdown {
        max-height: 200px; /* Adjust height for mobile */
        width: 100%; /* Ensure full width matches input */
        font-size: 14px; /* Keep the font size comfortable */
        position: relative; /* Ensure it fits properly within mobile layouts */
    }

    .list-group-item {
        font-size: 13px; /* Slightly reduce font size for tablets/phones */
        padding: 8px 10px; /* Adjust padding for mobile */
    }

    .suggestions-dropdown::-webkit-scrollbar {
        width: 6px; /* Reduce scrollbar width for mobile */
    }
}

/* For small phones (portrait mode) */
@media (max-width: 480px) {
    .suggestions-dropdown {
        max-height: 150px; /* Reduce height further for smaller screens */
        width: 100%; /* Keep width matching the input field */
        font-size: 12px; /* Adjust font size for small phones */
        position: relative; /* Ensure it doesn't overflow on mobile */
    }

    .list-group-item {
        font-size: 12px; /* Reduce font size for small phones */
        padding: 6px 8px; /* Reduce padding for smaller screens */
    }

    .suggestions-dropdown::-webkit-scrollbar {
        width: 5px; /* Further reduce scrollbar width for small phones */
    }

    .suggestions-dropdown::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 5px;
    }
}


/* Media query for small screens (below 768px width) */
@media (max-width: 768px) {
    .update-info {
        font-size: 14px !important; /* Slightly reduce the font size for smaller screens */
        flex-direction: column !important; /* Stack the elements vertically */
        text-align: left !important; /* Align text to the left for better readability */
    }

    .update-info strong {
        margin-bottom: 5px !important; /* Add space below the "Last update" text */
    }

    .update-info a {
        margin-top: 5px !important; /* Add space between the link and surrounding text */
        display: inline-block; /* Ensure it is treated as an inline element */
    }
}

/* Media query for extra small screens (below 375px width) */
@media (max-width: 375px) {
    .update-info {
        font-size: 12px !important; /* Further reduce the font size for very small screens */
        text-align: center !important; /* Center the text for better readability */
    }

    .update-info a {
        margin-top: 5px !important;
        display: block !important; /* Make the link appear on its own line */
    }
}

/* Search Bar Styling */
.search-bar {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  .search-bar input {
    width: 100% !important;
    height: 50px !important;
    padding: 10px 15px 10px 40px !important; /* Adjust padding to account for the search icon */
    font-size: 18px !important;
    margin-top: 10px !important;
    color: #000 !important;
  }
  
  .search-icon-container {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .search-icon {
    font-size: 18px;
    color: #cb0731;
  }
  
  .spinner-border {
    color: #cb0731 !important; /* Match spinner color with search icon */
  }
  
  /* Adjustments for small screens */
  @media (max-width: 768px) {
    .search-bar input {
      padding-left: 40px !important; /* Ensure padding remains on small screens */
    }
  }
  
  /* Responsive adjustments for small screens */
@media (max-width: 768px) {
    .icon-button-section {
        display: flex;
        flex-direction: row; /* Ensure all icons are in a single row */
        flex-wrap: nowrap;   /* Prevent wrapping to multiple rows */
        justify-content: space-around; /* Distribute the icons evenly */
    }

    .icon-item {
        margin-bottom: 0;
        flex: 1;
        max-width: 60px; /* Adjust width for icons on smaller screens */
    }

    .icon-circle {
        width: 50px;
        height: 50px; /* Adjust the size of the icons for small screens */
    }

    .icon-style {
        font-size: 20px; /* Adjust icon size for small screens */
    }

    .icon-item p {
        font-size: 12px !important; /* Adjust the text size for small screens */
    }
}

/* Badge Section */
.badge-section {
    display: flex;
    justify-content: center; /* Center the badges horizontally */
    align-items: center; /* Align badges vertically in the middle */
    gap: 10px; /* Space between badges */
    margin-top: 15px; /* Space above the badges */
  }
  
  /* Tooltip Styling */
  .badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 20px; /* Rounded corners for pill-like badges */
    font-size: 14px;
    font-weight: bold;
    color: white;
    position: relative; /* Required for positioning the tooltip */
    cursor: pointer; /* Change mouse cursor to pointer */
  }
  
  .badge-red {
    background-color: red;
  }
  
  .badge-darkred {
    background-color: #d9534f;
  }
  
  .badge-neutral {
    background-color: white;
    color: #333;
    border: 1px solid #ddd;
    display: inline-flex;
    align-items: center;
  }
  
  .badge-icon {
    background-color: #ccc;
    color: white;
    border-radius: 50%;
    padding: 2px 5px;
    margin-left: 5px;
    margin-top: 5px;
  }
  
  /* Tooltip text */
  .badge::before {
    content: attr(data-tooltip); /* Get the tooltip content from the data-tooltip attribute */
    position: absolute;
    bottom: 150%; /* Position above the badge */
    left: 50%;
    transform: translateX(-50%);
    background-color: #333; /* Dark background for tooltip */
    color: #fff; /* White text */
    padding: 8px 12px; /* Add more padding for better readability */
    border-radius: 5px;
    white-space: normal; /* Allow text to wrap if it's too long */
    font-size: 12px;
    width: 200px; /* Fixed width to maintain consistency */
    text-align: center; /* Center the text within the tooltip */
    opacity: 0; /* Start hidden */
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
  
  /* Tooltip arrow */
  .badge::after {
    content: "";
    position: absolute;
    bottom: 120%; /* Align the arrow with the tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent; /* Triangle pointing down */
    opacity: 0; /* Start hidden */
    transition: opacity 0.3s ease;
  }
  
  /* Show tooltip on hover */
  .badge:hover::before,
  .badge:hover::after {
    opacity: 1; /* Show tooltip */
  }
  
  

  
  
  
  
  