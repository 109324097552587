.selected-result-box {
    width: 70% !important;
    height: auto; /* Allow the height to adjust automatically */
    padding: 10px 20px;
    margin: 20px auto; /* Center the result box horizontally */
    display: flex; /* Use flexbox for vertical and horizontal centering */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    border: 2px solid #e9ecf6; /* Light border similar to the image */
    border-radius: 5px; /* Rounded corners */
    background-color: white; /* Light background */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    text-align: center; /* Ensure text is centered */
}

.selected-result-text {
    font-size: 22px; /* Slightly larger font for the text */
    font-weight: bold;
    color: #333; /* Darker text color */
    margin: 0; /* Remove margin for clean look */
    line-height: 1.5; /* Adjust line height for better spacing between lines */
}

/* Media queries for responsiveness */

/* For tablets and larger phones (portrait mode) */
@media (max-width: 768px) {
    .selected-result-box {
        width: 90%; /* Increase width for tablets */
        padding: 10px 15px; /* Adjust padding */
    }

    .selected-result-text {
        font-size: 20px; /* Adjust font size for tablets */
    }
}

/* For small phones (portrait mode) */
@media (max-width: 480px) {
    .selected-result-box {
        width: 100%; /* Full width for small phones */
        padding: 10px; /* Reduce padding */
    }

    .selected-result-text {
        font-size: 18px; /* Smaller font size for mobile */
        line-height: 1.4; /* Adjust line height */
    }
}


  /* Circles Section */
.circle-section {
    display: flex;
    justify-content: center; /* Center the circles horizontally */
    gap: 70px; /* Space between circles */
    margin: 30px 0;
    flex-wrap: nowrap; /* Prevent wrapping, keep them in a single line */
  }
  
  .circle {
    width: 170px;
    height: 170px;
    border: 1px solid #e9ecf6;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f6f8fd; /* Light background */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .circle-label {
    font-size: 14px;
    color: #333;
    text-align: center;
    margin-bottom: 5px;
  }
  
  .circle-value {
    font-size: 22px;
    font-weight: bold;
    color: #001f80;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    background-color: white;
    border-radius: 4px;
  }
  
  /* Media Queries for responsiveness */
  
  /* For devices with width less than or equal to 768px (Tablets) */
  @media (max-width: 768px) {
    .circle-section {
      gap: 30px; /* Reduce space between circles */
    }
  
    .circle {
      width: 150px; /* Reduce circle size for tablets */
      height: 150px;
    }
  
    .circle-label {
      font-size: 12px; /* Smaller text size */
    }
  
    .circle-value {
      font-size: 18px; /* Smaller value size */
      padding: 5px 8px; /* Adjust padding */
    }
  }
  
  /* For devices with width less than or equal to 576px (Mobile Phones) */
  @media (max-width: 576px) {
    .circle-section {
      gap: 20px; /* Reduce gap even further for mobile */
      justify-content: space-around; /* Distribute circles evenly */
    }
  
    .circle {
      width: 120px; /* Further reduce circle size for mobile */
      height: 120px;
    }
  
    .circle-label {
      font-size: 10px; /* Smaller text size */
    }
  
    .circle-value {
      font-size: 16px; /* Smaller value size */
      padding: 4px 6px; /* Adjust padding */
    }
  }
  
/* Default styling for larger screens */
.tabs-container {
    margin-top: 20px;
    width: 80%; /* Default width for larger screens */
    margin: 0 auto; /* Center the tab container */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .tabs-container {
        width: 100%; /* Set width to 100% for small screens */
    }
}


.tabs {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between; /* Ensure that items span the full width with no space between */
    margin: 0;
    border-bottom: 2px solid #cb0731; /* Add bottom border just below the tabs */
}

.tab-item {
    padding: 15px 20px;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: background-color 0.3s ease, color 0.3s ease;
    background-color: #f6f8fd; /* Default background */
    color: #333; /* Default text color */
    flex-grow: 1; /* Make all tabs take equal width */
}

.tab-item:hover {
    background-color: #e9ecf6; /* Hover background color */
}

.tab-item.active {
    background-color: #cb0731; /* Active background color */
    color: white; /* Active text color */
}

.tab-item.active .tab-icon {
    color: white; /* Active icon color */
}

.tab-icon {
    font-size: 22px;
    margin-bottom: 5px;
    color: #333; /* Default icon color */
}

.tab-content {
    
    padding: 20px;
    border: 1px solid #e9ecf6;
    border-radius: 5px;
    max-width: 100%;
    
    font-size: large;

    color: black;
   
}

/* Responsive behavior */
@media (max-width: 768px) {
    .tabs {
      flex-wrap: wrap;
    }
    .tab-item {
      padding: 10px 15px;
    }
}

.visa-options-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .visa-options-table th, 
  .visa-options-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
  }
  
  .visa-options-table th {
    background-color: #f6f8fd;
    color: #333;
    font-weight: bold;
  }
  
  .visa-options-table td a {
    color: #001f80;
  }
  
  .visa-options-table td button {
    background-color: white;
    border: 1px solid #ddd;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .green-icon {
    color: #28a745; /* Green color */
  }
  
  .red-icon {
    color: #dc3545; /* Red color */
  }
  .lin-link {
    border: 1px solid #333;
    padding: 2px 8px;
    text-decoration: none;
    color: black;
    font-weight: bold;
  }
  
  .lin-link:hover {
    cursor: pointer;
    background-color: #f0f0f0;
    color: #cb0731;
    text-decoration: underline;
  }
  
  /* Wrapper to add horizontal scroll on mobile devices */
.table-container {
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }
  
  @media (max-width: 768px) {
    .table-container {
      overflow-x: scroll; /* Add horizontal scroll */
    }
  
    .visa-options-table {
      min-width: 800px; /* Ensures the table has a minimum width for smaller screens */
    }
  }