/* .............. Footer section styles ............ */
.footer {
  background-color: #000;
  color: #fff;
}

.ft-1, .ft-2, .ft-3 {
  padding-bottom: 15px;
}

.ft-1 h3 {
  font-family: cursive;
}

.ft-1 p {
  padding: 1rem 2rem 1rem 0;
}

.footer-icon {
  display: flex;
  margin-top: 50px;
}

.footer-icon a {
  margin-right: 25px;
}

.footer-icon i {
  padding: 0.5rem;
  background-color: #fff;
  color: rgb(198, 17, 17);
  margin: 0 0.5rem;
  border-radius: 50%;
}

.fa-twitter:hover, .fa-facebook:hover, .fa-youtube:hover, .fa-instagram:hover, .fa-tiktok:hover {
  background-color: #000;
  color: #fff;
}

.ft-2 {
  text-align: center;
}

.ft-3 {
  text-align: center;
}

.ft-2 h3 {
  font-family: cursive;
}

.ft-2 ul {
  list-style: none;
  padding-left: 0;
}

.ft-2 ul li {
  padding: 1rem 2rem;
  transition: background-color 0.3s, color 0.3s;
}

.ft-2 ul li a:hover {
  background-color: white !important;
  color: red !important;
}

.ft-2 ul a {
  text-decoration: none;
  color: #fff;
  border-radius: 30px;
}

.ft-3 h3 {
  font-family: cursive;
}

.ft-3 p {
  font-weight: 500;
  padding: 0.3rem 0;
}

.ft-3 i {
  padding-right: 0.5rem;
}

.ft-3 img {
  height: 150px;
  width: 200px;
}

.ft-4 {
  background-color: #fff;
  color: rgb(198, 17, 17);
}

.ft-4 p {
  text-align: center;
  font-family: cursive;
  font-weight: bold;
}

/* .............. Media Queries ............ */
@media (max-width: 768px) {
.footer-icon {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.footer-icon a {
  margin: 10px;
}

.ft-1, .ft-2, .ft-3 {
  padding: 10px;
  text-align: center;
}

.ft-1 p {
  padding: 1rem 1rem;
}

.ft-3 img {
  height: 100px;
  width: 150px;
}

.ft-2 ul li {
  padding: 0.5rem 1rem;
}
}

@media (max-width: 576px) {
.footer-icon i {
  padding: 0.3rem;
  margin: 0 0.3rem;
}

.ft-1 h3, .ft-2 h3, .ft-3 h3 {
  font-size: 1.2rem;
}

.ft-3 img {
  height: 80px;
  width: 120px;
}

.ft-2 ul li {
  padding: 0.3rem 1rem;
}

.ft-4 p {
  font-size: 0.9rem;
}
}
