/* #001f80 blue
#cb0731 red */
.contact-us-heading{
    color: #001f80;
    font-weight: bold;
}
.contact-us-p{
    font-family: sans-serif;
    margin-bottom: 1rem;
}
.contact-us-email-container {
    display: flex;      /* Flexbox to align children inline */
    align-items: center; /* Centers the items vertically */
    white-space: nowrap; /* Prevents wrapping to ensure all content stays in one line */
  }
  
  .contact-us-email-container .email-link {
    color: #001f80;
    font-weight: 600;      /* Bold font weight for visibility */
    margin-left: 5px;      /* Adds a small margin for spacing between text and link */
  }

  .contact-us-call-button{
    background-color: white;
    color: #001f80;
  }
  .contact-us-call-button:hover, .contact-us-call-button:focus {
    background-color: #001f80;
    color: white;
  }
    .contact-us-image{
        height: 90%;
        width: 300px;
        
    }
    .contact-us-image-col {
        display: flex; /* Makes the column a flex container */
        align-items: center; /* Centers the content vertically */
        justify-content: center; /* Centers the content horizontally */
      }

      /* Australia Immigration Contact Center */
.contact-us-immigration-b{
    color: #cb0731;
}
.contact-us-immigration-p{
    margin-top: 2rem;
}

/* fill out form for Query */
.contact-us-query-form-h{
    color: #001f80;
    text-align: center;

}
.contact-us-qForm{
    height: 30%;
    width: 80%;
    
}
.form-label {
    color: #001f80; /* Blue color for form labels */
  }

  /* Our <b>Agent mission</b> is to exceed expectations */
  .contact-us-agent-b{
    color: #cb0731;
}

/*  */


.responsive-iframe {
  width: 100%; /* Default width */
  height: 130vh; /* Default height */
  overflow: hidden;
 
}

/* Tablet screens */
@media (max-width: 768px) {
  .responsive-iframe {
    height: 130vh;
  }
}

/* Styling for mobile screens */
@media (max-width: 576px) {
  .responsive-form-container {
    margin-left: 0; /* Align to the left side */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .responsive-iframe {
    height: 120vh;
    width: 100%; /* Use full width for better alignment */
    overflow-x: hidden;
   
   
  }
}
